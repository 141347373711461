#app {
	background: #eee;
	border-radius: 0px;
	
}
.tab_content{
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-wrap: wrap;
	align-items: flex-start;
	.card_item{
		cursor: pointer;
		background: #f8f8f8;
		position: relative;
		border-radius: 8px;
		margin-right: 10px;
		.text_title {
			line-height: 20px;
			font-weight: bold;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		.text_content {
			font-size: 12px;
			color: #666;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			line-height: 20px;
		}
		.overlay{
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: #0000001f;
			top: 0px;
			border-radius: 2px;
			
		}
	}
	
}
.tab_content_wel{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: flex-end;
	.card_item{
		cursor: pointer;
		background: #f8f8f8;
		position: relative;
		border-radius: 8px;
		margin-right: 10px;
		.text_title {
			line-height: 20px;
			font-weight: bold;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		.text_content {
			font-size: 12px;
			color: #666;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			line-height: 20px;
		}
	}
	
}
.p-editor-btn{
	position: relative;
	height: 100%;
	.p-editor-btn-bg{
		position: absolute;
		top: 33px;
		left: -13px;
		z-index: 5;
	}
	.p-editor-btn-icon{
		position: absolute;
		top: 41%;
		left: -10px;
		z-index: 9;
	}
}